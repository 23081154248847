a {
  text-decoration: none !important;
  color: #FFF !important;
}
a:hover {
  color: #FFF !important;
}
p.OrangeMenu{
  color:#FF9F07;
  font-size: 24px !important;
}

.close{
  width: 34px;
  height: 38px;
  margin-right: 40px;
  margin-top:20px;
}