@font-face {
  font-family: 'avenir_nextregular';
  src: url('AvenirNext-Regular.woff2') format('woff2'),
    url('AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'avenir_nextbold';
  src: url('AvenirNext-Bold.woff2') format('woff2'),
    url('AvenirNext-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'bebas';
  src: url('../bebas/BebasNeue-Regular.ttf') format('tff'),
    url('../bebas/BebasNeue-Regular.ttf') format('tff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
