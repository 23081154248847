.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}
.carousel-inner img {
  margin: auto;
}
.carou{
  margin-top:-80px;
   
}
.group_btn p{
  font-size: 16px;
  color:#000;
  margin-bottom: 10px !important;
  margin-top:20px !important;
  font-family: 'Bebas Neue', cursive;
}
.btn{
  height:65px !important;
  border-radius: 15px !important;
  font-family: 'avenir_nextbold';
}

img.image {
  max-height: 100vh !important;
  width: 100vw !important;
  
}
.container-fluid{
  margin:0px;
  padding:0px;
}
.carousel-caption{
  padding:0px !important;
}
/* 

.carou img {
  max-height: 100vh !important;
  width: 100vw !important;
}

.imgLoja{
  margin-top:20px;
  max-width: 270px;
  height:67px;
}


.carousel{
  height:100vh;
  width:100%;
}

@media screen and (max-width:900px){
  .carou{
    margin-top:0px;
    
  }
  .carou img {
    min-height: 'auto' !important;
    width: 100vw !important;
    
  }
 
  group_btn{
    display: flex;
    flex-direction: row;
    
  }
  button.btn{
    margin-top:20px;
    width: 319px !important;
  }
} */

@media screen and (max-width:900px){
  .btn_group{
    margin-left:-25px;
    margin-bottom: 60px;
  }
  .btn{
    margin-top:20px;
    height:45px !important;
    border-radius: 15px !important;
    width: 319px !important;
  }
  .carousel-item{
    padding:0px !important;
  }
  img.d-block{
    width: 100vw !important;
  }
}
